const mixin = {
  computed: {
    isPageLoading() {
      return this.$root.loading.visible;
    },
    pageLoadingProgress() {
      return this.$root.loading.progress;
    },
  },
  methods: {
    showPageLoading(options = {}) {
      this.$root.loading.opacity = options.opacity;
      this.$root.loading.content = options.content || 'Carregando...';
      this.$root.loading.progress = options.progress;

      this.$root.loading.visible = true;
    },
    hidePageLoading(timeout = 500) {
      setTimeout(() => this.$root.loading.visible = false, timeout)
    }
  }
}

export default mixin;